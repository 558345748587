<template>
  <div class="home">
    <SiteLinkViewer/>
  </div>
</template>

<script>
import SiteLinkViewer from '@/components/SiteLinkViewer.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld,
    SiteLinkViewer
  }
}
</script>
