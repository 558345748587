var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.showPage)?_c('div',[(!_vm.showNotFound)?_c('div',[_c('div',[_c('v-row',[_c('v-col',_vm._l((_vm.breadcrumbLinks),function(breadcrumb,pos){return _c('span',{key:pos},[(pos > 0)?_c('span',{staticStyle:{"margin":"3px"}},[_vm._v(">")]):_vm._e(),_c('span',{staticClass:"breadcrumb",class:{'active': pos === (_vm.breadcrumbLinks.length - 1)},attrs:{"disabled":true},on:{"click":function($event){return _vm.setBreadcrumb(breadcrumb, pos)}}},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")])])}),0)],1),_c('v-toolbar',{attrs:{"flat":""}},[(_vm.breadcrumbLinks.length > 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.activeSiteLink.title))]),_c('v-spacer')],1),_c('v-row',[(_vm.activeSiteLink.page)?_c('v-col',{class:{
            'col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10': _vm.activeSiteLink.page && _vm.activeSiteLink.children,
            'col-12': _vm.activeSiteLink.page && !_vm.activeSiteLink.children,
          }},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('vue-friendly-iframe',{ref:"previewIframe",attrs:{"src":'about:blank'},on:{"load":function($event){return _vm.onLoadIframe(_vm.activeSiteLink.page)}}})],1)]):_vm._e(),(_vm.activeSiteLink.children)?_c('v-col',{class:{
            'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2': _vm.activeSiteLink.page && _vm.activeSiteLink.children,
            'col-12': !_vm.activeSiteLink.page && _vm.activeSiteLink.children,
          }},[_c('div',_vm._l((_vm.activeSiteLink.children),function(siteLink,pos){return _c('CardListItem',{key:pos,attrs:{"siteLink":siteLink},on:{"click":function($event){return _vm.siteLinkClicked(siteLink)}}})}),1)]):(!_vm.activeSiteLink.children && !_vm.activeSiteLink.url && !_vm.activeSiteLink.page)?_c('div',[_vm._v(" This SiteLink is Neither a folder of URL. Please check your configuration for this SiteLink ")]):_vm._e()],1)],1)]):_c('div',[_c('div',[_c('center',[_c('h1',[_vm._v("Page or Link NOT FOUND")])])],1),_c('div',[_c('center',[_c('v-btn',{on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("Go To Home")])],1)],1)])]):_vm._e(),(!_vm.activeSiteLink.children && _vm.activeSiteLink.url)?_c('div',[_c('center',[_c('div',[_c('h1',[_vm._v("Redirecting now")])]),_c('div',[_vm._v("If it doesn't redirect automatically, please click "),_c('a',{attrs:{"href":_vm.activeSiteLink.url}},[_vm._v("here")])])])],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }